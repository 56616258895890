
var tg;
tg = tg || (function () {
    var pleaseWaitDiv = $('<div class="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-body"><strong>bitte warten...</strong></div></div></div></div>');
    return {
        showPleaseWait: function() {
            pleaseWaitDiv.modal();
        },
        hidePleaseWait: function () {
            pleaseWaitDiv.modal('hide');
        },
        showErrorDialog: function(message) {
            message = typeof message !== 'undefined' ? message : null;

            $('#genericErrorDialog .default-message').show();
            $('#genericErrorDialog .specific-message').hide();
            $('#genericErrorDialog .specific-message').html();

            if (message) {
                $('#genericErrorDialog .default-message').hide();
                $('#genericErrorDialog .specific-message').show();
                $('#genericErrorDialog .specific-message').html(message);
            }

            $('#genericErrorDialog').modal('show');
        },
        lockModalDialogs: function() {
            $('.modal .btn').prop('disabled', true);
            $('.modal').on('hide.bs.modal.prevent', function (e) {
                e.preventDefault()
            });
        },
        unlockModalDialogs: function() {
            $('.modal .btn').prop('disabled', false);
            $('.modal').off('hide.bs.modal.prevent');
        },
        centerSeatplan: function() {
            // In die mitte des Scrollbaren Bereiches des Sitzplanes scrollen
            // wenn der Sitzplan nicht komplett auf den Bildschirm passt
            if ($('#seatplan').length) {
                if ($('#seatplan').get(0).scrollWidth > $('.seatplan-wrapper').width()) {
                    $('.seatplan-wrapper').scrollLeft(($('#seatplan').get(0).scrollWidth - $('.seatplan-wrapper').width()) / 2);
                }
            }
        }
    };
})();

$(function () {
    // CSRF-Token an alle XHRs anhängen
    $.ajaxPrefilter(function(options) {
        if (! options.beforeSend) {
            options.beforeSend = function (xhr) {
                var token = $('meta[name="csrf_token"]').prop('content');
                if (token) {
                    xhr.setRequestHeader('X-CSRF-TOKEN', token);
                }
            }
        }
    });

    $('body').on('click', '.selected-seats .seat .deselect-ticket', function() {
        var $ticket = $('#seatplan .seat[data-ticket-id="' + $(this).parent('.seat').data('ticket-id') + '"]');
        if ($ticket) {
            $ticket.click();
        }
    });

    $('body').on('click', '#seatplan .seat', function(){
        var $this = $(this);

        if ($this.hasClass('reserved')) {

        }
        else if ($this.hasClass('selected')) {
            $this.addClass('selecting').removeClass('selected').html('<span class="glyphicon glyphicon-hourglass" aria-hidden="true"></span>');

            $.ajax({
                url: '/tickets/' + $this.data('ticket-id') + '/deselect',
                type: 'POST',
                success: function(resp) {
                    if (resp.success == true) {
                        // Sitz im Sitzplan anpassen
                        var $ticket = $('#seatplan .seat[data-ticket-id="' + resp.ticket_id + '"]');
                        $ticket
                            .removeClass('selecting')
                            .data('selected_at', null)
                            .html('');

                        // Sitz von Liste der ausgewählten Sitze entfernen
                        $('.selected-seats [data-ticket-id="' + resp.ticket_id + '"]').remove();
                        if ($('.selected-seats .seat:not(.placeholder)').length == 0) {
                            $('.selected-seats .placeholder').show();
                        }
                    } else {
                        // Error...
                        $('#seatplan .seat[data-ticket-id="' + resp.ticket_id + '"]').removeClass('selecting').html('');
                        tg.showErrorDialog(resp.error);
                    }
                },
                error: function(resp) {
                    // Error...
                    $('#seatplan .seat[data-ticket-id="' + resp.ticket_id + '"]').removeClass('selecting').html('');
                    tg.showErrorDialog();

                }
            });
        }
        else if ($this.hasClass('selecting')) {

        }
        else {
            $this.addClass('selecting').html('<span class="glyphicon glyphicon-hourglass" aria-hidden="true"></span>');

            $.ajax({
                url: '/tickets/' + $this.data('ticket-id') + '/select',
                type: 'POST',
                //data: { element_id: xxx },
                success: function(resp) {
                    console.log(resp);
                    if (resp.success == true) {
                        // Sitz im Sitzplan anpassen
                        var $ticket = $('#seatplan .seat[data-ticket-id="' + resp.ticket_id + '"]');
                        $ticket
                            .removeClass('selecting')
                            .addClass('selected')
                            .data('selected_at', resp.selected_at)
                            .html('<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>');

                        // Sitz zur Liste der ausgewählten Sitze hinzufügen
                        $('.selected-seats .placeholder').hide();
                        $('.selected-seats').append('<li class="selected seat ' + $ticket.data('category') + '" data-ticket-id="' + resp.ticket_id + '">' + $ticket.data('r') + '-' + $ticket.data('n') + ' <span class="glyphicon glyphicon-trash deselect-ticket" aria-hidden="true"></span></li>');
                    } else {
                        // Error...
                        $('#seatplan .seat[data-ticket-id="' + resp.ticket_id + '"]').removeClass('selecting').html('');
                        tg.showErrorDialog(resp.error);
                    }
                },
                error: function(resp) {
                    // Error...
                    $('#seatplan .seat[data-ticket-id="' + resp.ticket_id + '"]').removeClass('selecting').html('');
                    tg.showErrorDialog();
                }
            });

        }
    });

    $('body').on('click', '.cancel-booking', function() {
        tg.lockModalDialogs();
        $('#seatplan .seat.selected').addClass('selecting').removeClass('selected').html('<span class="glyphicon glyphicon-hourglass" aria-hidden="true"></span>');

        $.ajax({
            url: '/tickets/' + tg.constants.all_ids + '/deselect',
            type: 'POST',
            success: function(resp) {
                tg.unlockModalDialogs();
                if (resp.success == true) {
                    if ($('body').hasClass('ticket-selection')) {
                        // Sitze im Sitzplan anpassen
                        $('#seatplan .seat.selecting')
                            .removeClass('selecting')
                            .data('selected_at', null)
                            .html('');

                        // Sitz von Liste der ausgewählten Sitze entfernen
                        $('.selected-seats .seat').remove();
                        $('.selected-seats .placeholder').show();

                        // Dialog ausblenden
                        $('#cancelDialog').modal('hide');
                    } else {
                        location.reload(true);
                    }
                } else {
                    // Error...
                    tg.showErrorDialog(resp.error);
                }
            },
            error: function(resp) {
                tg.unlockModalDialogs();
                // Error...
                tg.showErrorDialog();
            }
        });

    });

    $('body').on('click', '.error-refresh-page', function() {
        location.reload(true);
    });

    $('body').on('click', '.change-event', function(e) {
        $this = $(this);
        // prüfen ob der Termin deaktiviert ist
        // (z.B. wenn es sich um den bereits angezeigten Termin handelt)
        if ($this.parent('li').hasClass('disabled')) {
            return false;
        }

        // prüfen ob Sitzplätze ausgewählt sind
        if ($('#seatplan .seat.selected:not(.placeholder)').length != 0) {
            $('#confirmChangeEventDialog').modal('show');
            $('.confirm-change-event').prop('href', $this.prop('href'));
        }
        else {
            tg.showPleaseWait();
            return true;
        }
        return false;
    });

    if ($('body').hasClass('ticket-selection')) {
        // Sitzplan/Ticket-Plan zentrieren
        tg.centerSeatplan();
    }

    // Funktionalität von Links in deaktivierten Punkten der Fortschrittsanzeige unterbinden
    $('body').on('click', '.nav-wizard li.disabled a, .nav-wizard li.active a', function() {
        return false;
    });

});
